import React from 'react';
import { Link } from 'react-router-dom';
// import { Row, Col, Card } from 'react-bootstrap'
import ReactGA from "react-ga4";


function Footer2() {

    const eventTrack = (category, action, label) => {
        //console.log("GA event:", category, ":", action, ":", label);
        ReactGA.event({
          category: category,
          action: action,
          label: label,
        });
      }


    return (
        <>
            <div className="footer dashboard">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-8 col-12">
                            <div className="copyright">
                                <p>© Copyright {new Date().getFullYear()}  <Link to={'#'}>AltraOne</Link> I
                                All Rights Reserved</p>
                            </div>
                        </div>
                        <div className="col-sm-4 col-12">
                            <div className="footer-social">
                                <ul>
                                    {/*
                                    <li><Link to={'#'}><i className="fa fa-facebook"></i></Link></li>
                                    <li><Link to={'#'}><i className="fa fa-twitter"></i></Link></li>
                                    <li><Link to={'#'}><i className="fa fa-linkedin"></i></Link></li>
                                    <li><Link to={'#'}><i className="fa fa-youtube"></i></Link></li>
                                    */}
                                    <li>
                                        <Link to={{ pathname: "https://medium.com/@hp.in.2019" }} target="_blank" onClick={eventTrack.bind(this, "Footer", "Medium", "Social")}>
                                            <i className="fa fa-medium"></i>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to={{ pathname: "https://twitter.com/hpin2019" }} target="_blank" onClick={eventTrack.bind(this, "Footer", "Twitter", "Social")}>
                                            <i className="fa fa-twitter"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={{ pathname: "https://t.me/altran_eod_scans" }} target="_blank" onClick={eventTrack.bind(this, "Footer", "Telegram", "Social")}>
                                            <i className="fa fa-telegram"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer2;