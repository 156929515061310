import React from 'react';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { NavLink } from 'react-router-dom';


const home = (
    <Tooltip id="home">
        Home
    </Tooltip>
);
const exchange = (
    <Tooltip id="exchange">
        Exchange
    </Tooltip>
);
const accounts = (
    <Tooltip id="accounts">
        Accounts
    </Tooltip>
);
const data = (
    <Tooltip id="data">
        Data
    </Tooltip>
);
const settings = (
    <Tooltip id="settings">
        Setting
    </Tooltip>
);

function Sidebar() {

    return (
        <>
            <div className="sidebar-new">


                <div className="menu">
                    <ul>
                    
                        <li className="nav-title">
                            <NavLink to={"/help"} activeClassName="active-link">
                                
                                    <span className="nav-item">
                                    
                                        Read this First
                                    </span>
                            
                            </NavLink>
                        </li>
                        <hr></hr>
                        <li className="nav-title">NSE</li>
                        <li>
                            <NavLink to={"/view/nse"} activeClassName="active-link">
                                <OverlayTrigger placement="right" overlay={home}>
                                    <span className="nav-item">
                                        <i className="mdi mdi-decagram-outline"></i>
                                        Overview
                                    </span>
                                </OverlayTrigger>
                            </NavLink>

                        </li>
                        <li>
                            <NavLink to={"/details/nse"} activeClassName="active-link">
                                <OverlayTrigger placement="right" overlay={home}>
                                    <span className="nav-item">
                                        <i className="mdi mdi-decagram-outline"></i>
                                    Details
                                </span>
                                </OverlayTrigger>
                            </NavLink>

                        </li>
                        <li>
                            <NavLink to={"/ema-views/nse"} activeClassName="active-link">
                                <OverlayTrigger placement="right" overlay={home}>
                                    <span className="nav-item">
                                        <i className="mdi mdi-decagram-outline"></i>
                                    EMA View
                                </span>
                                </OverlayTrigger>
                            </NavLink>

                        </li>

                        <hr></hr>
                        <li className="nav-title">
                            <NavLink to={"/tvscripts"} activeClassName="active-link">
                                
                                    <span className="nav-item">
                                    
                                        TradingView Scripts
                                    </span>
                            
                            </NavLink>
                        </li>
                        


                    </ul>
                </div>
            </div>
        </>
    )
}

export default Sidebar;