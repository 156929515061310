import React, { useEffect, useState } from "react";
import { Nav, Tab, ProgressBar, Button } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-rangeslider/lib/index.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { Link, useParams } from "react-router-dom";
import TradingViewWidget, { Themes } from "react-tradingview-widget";
import DepthChart from "../charts/depth";
import RangeSlider from "../element/range-slider";
import Footer2 from "../layout/footer2";
import Header2 from "../layout/header2";
import Sidebar from "../layout/sidebar";
import BootstrapTable from 'react-bootstrap-table-next';

import { testService } from "../../api/_services/test.service";
import Signals from "../layout/signals";



function ViewDetails() {
    const [maxScore, setMaxScore] = useState(50)
    const [tableData, setTableData] = useState(null);
    const [watchlist, setWatchlist] = useState(null)
    const [color, setColor] = useState(null)
    const [selectedTopFilter, setSelectedTopFilter] = useState(10)
    const params = useParams();

    useEffect(() => {
        fetchAllLists();
    }, [params.id])


    useEffect(() => {
        if (watchlist && watchlist.watchlists.length) {

            const tableData = watchlist.watchlists.map((watchlist) => {
                return fetchTableData(watchlist)
            })
            // console.log('tableData', tableData)


            resolvePromises(tableData)
        }
    }, [watchlist])

    const resolvePromises = async (tableData) => {
        const finalData = await Promise.all([...tableData]);

        setTableData(finalData);
    }
    const fetchAllLists = async () => {
        // console.log('lastSegment', params)
        const id = params.id
        const data = await testService.getAllWatchlists(id);
        // console.log('data', data.data)
        // setColor(data.data.config.color)
        const formattedWatchlists = data.data.watchlists.map(watchlist => {
            return Object.entries(watchlist).map(item => {
                return {
                    label: item[0],
                    value: item[1],
                }
            })
        }).flat()
        // .sort((a, b) => {
        //     if (a.value > b.value) { return 1; }
        //     if (a.value < b.value) { return -1; }
        //     return 0;
        // });
        // console.log('formattedWatchlists', formattedWatchlists)
        setWatchlist({
            config: data.data.config,
            watchlists: formattedWatchlists
        })
    }

    const checkIfPositive = (num) => {
        return num > 0
    }

    const fetchTableData = async (watchlist) => {

        const data = await testService.getData(watchlist.value);

        // console.log('data', data)

        const formattedData = Object.entries(data.data.all).map(item => {

            // console.log(item[1].ema_score.co_signals)
            const tempArr = [];
            const signals = Object.entries(item[1].ema_score.co_signals).map(item => {
                return item[1] !== 0 ? [...tempArr, { label: item[0], value: item[1] }] : []
            }).flat();

            // console.log('signals', signals)
            return {
                symbol: item[0],
                // ltp: item[1].price.close,
                // '1d': item[1].returns['1d'],
                // '5d': item[1].returns['5d'],
                // '1m': item[1].returns['30d'],
                // '6m': item[1].returns['180d'],
                'mtf_score': item[1].scores['total_score'],
                // 'rsi_score': item[1].scores['total_rsi_score'],
                // 'ema_score': item[1].scores['total_ema_score'],
                // 'signals': item[1].ema_score.co_signals
                // 'signals': signals.filter(signal => signal.label !== 'score') // removing score from the signals list
            }
        }).sort((a, b) => b.mtf_score - a.mtf_score) // sorting data by mtf_score
        // console.log('formattedData', formattedData, watchlist)
        const getSymbolLink = (symbol) => `https://in.tradingview.com/chart/?symbol=${encodeURIComponent(symbol)}`;
        const columnData = [
            {
                dataField: 'symbol',
                text: 'Symbol',
                sort: true,
                // headerClasses: 'wid15'
                headerStyle: { width: '150px' },
                formatter: (cell, row) => (
                    <a href={getSymbolLink(cell)} target="_blank" rel="noopener noreferrer">
                        {cell}
                    </a>
                )
            },
            // {
            //     dataField: 'ltp',
            //     text: 'LTP',
            //     sort: true,
            //     align: (cell, row, rowIndex, colIndex) => {
            //         return 'right';
            //     },
            //     headerAlign: 'right'
            // },

            {
                dataField: 'mtf_score',
                text: 'MTF Score',
                sort: true,
                formatter: (cell, row, id) => barFormatter(cell, row, id, 'hide_per')
            },
        ]

        // console.log(formattedData)
        const top5Data = filterTopStats(formattedData, selectedTopFilter)
        // console.log('top5Data', top5Data)

        const finalData = {
            ...watchlist,
            columns: columnData,
            rows: top5Data,
            all: formattedData,
        }
        return finalData;
    }

    // useEffect(() => {
    //     console.log('tableData', tableData)
    // }, [tableData])

    // const onTabChange = (tab) => {
    //     // console.log('tab', tab)
    //     fetchTableData(tab)
    // }


    const scoreFormatter = (cell, row) => {
        return (
            <span className={`${checkIfPositive(cell) ? 'text-success' : 'text-danger'}`}>{cell}%</span>
        );
    }

    const barFormatter = (cell, row, id, percentage) => {
        return (
            <span className="wid15">
                <ProgressBar now={cell} label={percentage === 'hide_per' ? `${cell}` : `${cell}%`} variant={checkIfPositive(cell) ? 'success' : 'danger'} max={checkIfPositive(cell) ? maxScore : -maxScore} />
            </span>
        );
    }
    const signalFormatter = (cell, row) => {
        // console.log(cell)
        return (
            // <span style={{fontSize: '12px'}}> 
            //     {cell.length > 1 ? <span className="bg-success text-white" style={{padding: '5px 7px', borderRadius: '2px', fontWeight: 600}}>{watchlist ? watchlist.config.columns[cell[0]]?.[1].name : null}</span> : 
            //     cell.length === 1 ? <span className="bg-success text-white" style={{padding: '5px 7px', borderRadius: '2px', fontWeight: 600}}>{watchlist ? watchlist.config.columns[cell[0]]?.[1].name: cell[0]}</span> : ''}
            //     <span style={{fontSize: '12px'}}>{cell.length > 1 ? ` +${cell.length - 1}` : ''}</span>
            // </span>
            <span style={{ fontSize: '10px' }}>
                {
                    cell.map((item, index) => {
                        return (<span key={index}>
                            {item.value === 1 ?
                                (<span className="bg-success text-white" style={{ padding: '3px 5px', marginRight: '5px', borderRadius: '2px', fontWeight: 500 }}>{watchlist ? watchlist.config.columns[item.label]?.[1].name : null}</span>) :
                                <span className="bg-danger text-white" style={{ padding: '3px 5px', marginRight: '5px', borderRadius: '2px', fontWeight: 500 }}>{watchlist ? watchlist.config.columns[item.label]?.[-1].name : null}</span>}
                        </span>
                        )
                    })
                }
            </span>
        );
    }

    const filterTopStats = (data, num) => {
        // console.log('filterTopStats', data)
        const postive = data.filter(item => item.mtf_score > 0) || [];
        const negative = data.filter(item => item.mtf_score < 0) || [];
        // console.log('postive', postive, negative)
        return [...postive.slice(0, num), ...negative.slice(Math.max(negative.length - num, 0))]
    }

    const [sidebar, setSidebar] = useState(true)

    useEffect(() => {
    }, [sidebar])


    return (
        <>
            <Header2 setSidebar={setSidebar} />
            <div className={`wrapper ${sidebar ? "sidebar-open" : ""}`}>



                <div className="content-body" id="dashboard">
                    <div className="container-fluid">
                        <Signals />
                        <div className="row">
                            {

                                tableData && tableData.length ?
                                    tableData.map((watchlist) => (
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-xxl-3" key={watchlist.value} style={{ padding: '0.6rem' }}>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h4 className="card-title">
                                                                {watchlist.label}
                                                            </h4>
                                                            <Link to={`/EMA-Views/${params.id}?selectedTab=${encodeURIComponent(watchlist.label)}`}>
                                                                <h6 style={{ margin: 0, fontSize: '0.76563rem' }}>View Details</h6>
                                                            </Link>
                                                        </div>
                                                        <PerfectScrollbar>
                                                            <div className="card-body trade-history">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable noDataIndication="No Data Available" bootstrap4 defaultSorted={[{ dataField: 'mtf_score', order: 'desc' }]} keyField='symbol' data={watchlist.rows} columns={watchlist.columns} bordered={false} />
                                                                </div>
                                                            </div>
                                                        </PerfectScrollbar>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )) : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer2 />
        </>
    );
}

export default ViewDetails;
