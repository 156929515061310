import React, { useEffect, useState, useCallback } from "react";
import { Nav, Tab, ProgressBar, Button } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-rangeslider/lib/index.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Footer2 from "../layout/footer2";
import Header2 from "../layout/header2";
import Sidebar from "../layout/sidebar";
import Signals from "../layout/signals";
import BootstrapTable from 'react-bootstrap-table-next';
//import { format } from 'date-fns';
import { testService } from "../../api/_services/test.service";



function TVList() {

    const [sidebar, setSidebar] = useState(true)

    useEffect(() => {
    }, [sidebar])


    return (
        <>
            <Header2 setSidebar={setSidebar} />
            <div className={`wrapper ${sidebar ? "sidebar-open" : ""}`}>




                <div className="content-body" id="readfirst">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-6 col-xxl-12 col-lg-12 col-xxl-6">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="content-container custom-color">
                                            My TradingView Scripts
                                        </h3>

                                    </div>
                                    <PerfectScrollbar className="content-container">
                                        <div className="card-body readfirst">

                                            
                                            <div className="row">
                                                <div className="col-xl-11">
                                                    <h6 className="content-text">
                                                        All scripts are published as protected scripts (TradingView is not allowing to publish them publicly). So you have to click on the link and add it as favorite indicator to use it.
                                                    </h6>
                                                    <br></br>
                                                    <div className="app-content">


                                                        <h4 className="mt-2 mb-3 custom-color">
                                                        <a href="https://in.tradingview.com/script/69rkFMLT-Support-Resistance-Lines/" target="_blank">1. Automatic Support and Resistance Lines.</a>
                                                        </h4>
                                                        <h6 className="content-text">
                                                        This script draws various support and resistance lines automatically.

                                                        Following are supported :
                                                        <div className="apps-content">
                                                            <ul>
                                                                <li><i className="la la-check"></i>Day's High and Low Lines (DH,DL)</li>
                                                                <li><i className="la la-check"></i>Prev Day's High and Low Lines ( PDH, PDL)</li>
                                                                <li><i className="la la-check"></i>Weekly High and Low lines</li>
                                                                <li><i className="la la-check"></i>Monthly High and Low Lines</li>
                                                                <li><i className="la la-check"></i>Horizontal lines at Day EMAs even if you are in lower timeframe (Default 20,50 and 200).</li>
                                                            </ul>
                                                        </div>
                                                        All lines are configurable, e.g. choose whichever you want to draw.
                                                        EMA lines are calculated based on symbol's Day EMAs. Even if you are on lower timeframe, (5m or 15m or 1H), it will show 20, 50, 200 DEMA lines so you don't have to go to Day TF to get Day EMAs.
                                                            </h6>
                                                        

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xl-10">
                                                <a href="https://in.tradingview.com/script/69rkFMLT-Support-Resistance-Lines/" target="_blank">
                                                    <img
                                                        className="img-fluid content-img"
                                                        src={require("./../../images/blog/srlines.png")}
                                                        alt="..."
                                                        height="100"
                                                    />
                                                    </a>
                                                </div>
                                            </div>
                                            <br></br> <br></br> <br></br>
                                            <div className="row">
                                                <div className="col-xl-11">
                                                    <div className="app-content">


                                                        <h4 className="mt-2 mb-3 custom-color">
                                                        <a href="https://in.tradingview.com/script/kRTVyK1n-Closing-Price-tracker/" target="_blank">2. BankNifty Nifty Closing Settlement Price Tracker.</a>
                                                        </h4>
                                                        <h6 className="content-text">
                                                        NSE's closing price is calculated as a weighted average of the last 30 minutes of trading. As a result, the closing price on the daily charts also does not match the LTP since it is updated using the NSE bhavcopy's closing price. Due to this behavior, in last 30 mins of trading, we see major moves but we don't know at what price final settlement will happen by looking at LTP on chart. This indicator helps in this case and in table, after 3pm on 1m chart, it starts showing final settlement price vs LTP. It also has option to draw this settlement price line on chart anchored at 3 pm. To make life easy, it will show BankNifty Futures, BankNifty spot, Nifty Futures and Nifty spot's final settlement price in one table.              </h6>
                                                        <h6 className="content-text">
                                                        Reason this price is required is that Option prices relies on this settlement prices while we might be just looking at LTP which is wrong price to calculate option price.</h6>
                                                        
                                              
                                                        

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xl-10">
                                                <a href="https://in.tradingview.com/script/kRTVyK1n-Closing-Price-tracker/" target="_blank">
                                                    <img
                                                        className="img-fluid content-img"
                                                        src={require("./../../images/blog/closing.png")}
                                                        alt="..."
                                                        height="100"
                                                    />
                                                    </a>
                                                </div>
                                            </div>
                                            <br></br> <br></br> <br></br>
                                            
                                            <div className="row">
                                                <div className="col-xl-11">
                                                    <div className="app-content">


                                                        <h4 className="mt-2 mb-3 custom-color">
                                                        <a href="https://in.tradingview.com/script/tvrUJdtM-No-Scalping-Zone/" target="_blank">3. No Scalping Zone.</a>
                                                        </h4>
                                                        <h6 className="content-text">
                                                        Time frame : 3 min</h6>
                                                        <h6 className="content-text">
                                                        Charts : BankNifty futures or NIfty futures.</h6>
                                                        <h6 className="content-text">
                                                        The script color codes volume bars in Light green, Green, Gray, Light red, and Dark red colors.</h6>
                                                        <h6 className="content-text">

                                                        it is developed to work best with Nifty and Banknifty futures chart on 3 or. 5 min time frame.</h6>
                                                        <h6 className="content-text">

                                                        How to use :
                                                        <div className="apps-content">
                                                            <ul>
                                                                <li><i className="la la-check"></i>Gray bars to avoid scalping.</li>
                                                                <li><i className="la la-check"></i>Green / Light green for going long.</li>
                                                                <li><i className="la la-check"></i>Red / Light red for going short.</li>
                                                                <li><i className="la la-check"></i>It uses RSI, SuperTrend, VWAP, Previous One hour price range and first 15m candle price range to decide scalping zones.</li>
                                                                <li><i className="la la-check"></i>Use mainly Gray zones to "Avoid scalping trades".</li>
                                                            </ul>
                                                        </div>
                                                        </h6>
                                                        
                                                        
                                                        <h6 className="content-text">
                                                            Read more details in the script's description.
                                                            </h6>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xl-10">
                                                <a href="https://in.tradingview.com/script/tvrUJdtM-No-Scalping-Zone/" target="_blank">
                                                    <img
                                                        className="img-fluid content-img"
                                                        src={require("./../../images/blog/noscalping.png")}
                                                        alt="..."
                                                        height="100"
                                                    />
                                                    </a>
                                                </div>
                                            </div>
                                            
                                            <div className="row">
                                                <div className="col-xl-11">
                                                    <div className="app-content">


                                                        <h4 className="mt-2 mb-3 custom-color">
                                                        <a href="https://in.tradingview.com/script/fABFkhWh-MTF-Score/" target="_blank">4. MTF Momentum Score for any stock.</a>
                                                        </h4>
                                                        <h6 className="content-text">
                                                        It calculates MultTimeFrame momentum score for the stock with a scoring formula using RSI, RSI Change, Super Trend (Default 10,2 used) and ST Slope.For intraday, Score is calculated in 5m, 15m and 1H time frame and shown in left side of the table. For positional, Score is calculated in D, W and M time frame and shown to the right side of the table. 
                                                        
                                                            </h6>
                                                        <h6 className="content-text">
                                                            Read more details in the script's description.
                                                            </h6>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xl-10">
                                                <a href="https://in.tradingview.com/script/fABFkhWh-MTF-Score/" target="_blank">
                                                    <img
                                                        className="img-fluid content-img"
                                                        src={require("./../../images/blog/mtf.png")}
                                                        alt="..."
                                                        height="100"
                                                    />
                                                    </a>
                                                </div>
                                            </div>
                                            <br></br> <br></br> <br></br>
                                            
                                            <div className="row">
                                                <div className="col-xl-10">
                                                    <div class="empty"></div>
                                                </div>
                                            </div>
                                            
                                            

                                        </div>

                                    </PerfectScrollbar>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div></div>
                </div>
            </div>
            <Footer2 />
        </>
    );
}

export default TVList;
