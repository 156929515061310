import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import Sidebar from "./sidebar";
import { useState, useEffect } from "react";

function Header2(props) {

    const [sidebarOpen, setSidebarOpen] = useState(window.innerWidth > 1100);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 1100) {
                setSidebarOpen(true);
            } else {
                setSidebarOpen(false);
            }
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };
    try {
        props.setSidebar(sidebarOpen);
    } catch (e) {
        console.log(e);
    }

    return (
        <>
            <div className="header-new">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <nav className="navbar navbar-light px-0">
                            <div >
                                <button className={sidebarOpen ? "navbar-toggler open" : "navbar-toggler"} type="button" onClick={toggleSidebar}>
                                    <FaBars className="custom-color" />
                                </button>
                                <Link to={"/view/nse"} >  <span className="header-items" style={{paddingRight: "2rem"}}>DashBoard</span></Link>
                                <Link to={"/india"} >  <span className="header-items" style={{paddingRight: "2rem"}}>India Stocks</span></Link>
                                <Link to={"/us"} >  <span className="header-items" style={{paddingRight: "2rem"}}>US Stocks</span></Link>
                                </div>     
                                    
                                <div className="sidebar-brand d-none d-md-flex" to="/">
                                    <a href="/view/nse">AltraOne</a>
                                </div>
                            </nav>

                        </div>
                    </div>
                </div>
            </div>

            {sidebarOpen && <Sidebar />}
        </>
    );
}

export default Header2;
