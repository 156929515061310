



import axiosConfig from '../_helpers/axiosConfig'

export const testService = {
    getData,
    getSummary,
    getAllWatchlists
};

async function getData(stockName) {
    try {
        const response = await axiosConfig.get(`/wl/${stockName}`);
        if(!response) return;

        return response.data;
    } catch (error) {
        return error;
    }
}

async function getSummary(stockName) {
    try {
        const response = await axiosConfig.get(`/summary/${stockName}`);
        if(!response) return;

        return response.data;
    } catch (error) {
        return error;
    }
}

async function getAllWatchlists(id) {
    try {
        const response = await axiosConfig.get(`/list/${id}`);
        if(!response) return;

        return response.data;
    } catch (error) {
        return error;
    }
}
