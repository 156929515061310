import React, { useEffect, useState, useCallback } from "react";
import { Nav, Tab, ProgressBar, Button } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-rangeslider/lib/index.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Footer2 from "../layout/footer2";
import Header2 from "../layout/header2";
import Sidebar from "../layout/sidebar";
import Signals from "../layout/signals";
import BootstrapTable from 'react-bootstrap-table-next';
//import { format } from 'date-fns';
import { testService } from "../../api/_services/test.service";



function Help() {

    const [sidebar, setSidebar] = useState(true)

    useEffect(() => {
    }, [sidebar])


    return (
        <>
            <Header2 setSidebar={setSidebar} />
            <div className={`wrapper ${sidebar ? "sidebar-open" : ""}`}>




                <div className="content-body" id="readfirst">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-6 col-xxl-12 col-lg-12 col-xxl-6">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="content-container custom-color">
                                            Please Read this first
                                        </h3>

                                    </div>
                                    <PerfectScrollbar className="content-container">
                                        <div className="card-body readfirst">

                                            
                                            <div className="row">
                                                <div className="col-xl-11">
                                                    <div className="app-content">


                                                        <h4 className="mt-2 mb-3 custom-color">
                                                            What is MTF Momentum Score ?
                                                    </h4>
                                                        <h6 className="content-text">
                                                            MTF Momentum Score means MultiTime Frame Momentum Score. I'm using it for momentum trading of stocks in F&O category.<br></br>
                                                            Currently this score is calculated by giving different weightage for trend and momentum indicators in daily, weekly and monthly timeframe to arrive at one score. Higher the number, more bullish is momentum.
                                                            </h6>
                                                        

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xl-5 col-lg-5 col-md-5">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h4 className="custom-color">What increases the MTF score</h4>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="apps-content">
                                                            <ul>
                                                                <li><i className="la la-check"></i> Different positive weightage to RSI range above 50.</li>
                                                                <li><i className="la la-check"></i> RSI increasing in different Timeframe.</li>
                                                                <li><i className="la la-check"></i> Lower TF RSI crossing over Higher TF RSI.</li>
                                                                <li><i className="la la-check"></i> Positive placement of previous day's closing price with respect to 4 EMAs in Daily Timeframe, 8DEMA, 20DEMA, 50 DEMA, 200 DEMA</li>
                                                                <li><i className="la la-check"></i> Lower DEMA crossover to Higher DEMA e.g. 8/20 DEMA crossover, 50/200 DEMA crossover</li>
                                                                <li><i className="la la-check"></i> Positive change in each DEMA, e.g. if EMA is increasing or sloping upwards</li>
                                                                <li><i className="la la-check"></i> Also 8Week EMA and 30Week EMAs are also used in Weekly Timeframe with same scoring.</li>
                                                            </ul>
                                                        </div></div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-5 col-lg-5 col-md-5">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h4 className="custom-color">What reduces the MTF score</h4>
                                                        </div>
                                                        <div className="card-body">
                                                        <div className="apps-content">
                                                            <ul>
                                                            <li><i className="la la-check"></i> Different negative weightage to RSI range below 50.</li>
                                                                <li><i className="la la-check"></i> RSI decreasing in different Timeframe.</li>
                                                                <li><i className="la la-check"></i> Lower TF RSI crossing below Higher TF RSI.</li>
                                                                <li><i className="la la-check"></i> Negative placement of previous day's closing price with respect to 4 EMAs in Daily Timeframe, 8DEMA, 20DEMA, 50 DEMA, 200 DEMA</li>
                                                                <li><i className="la la-check"></i> Lower DEMA crossing under Higher DEMA e.g. 8/20 DEMA crossunder, 50/200 DEMA crossunder</li>
                                                                <li><i className="la la-check"></i> Negative change in each DEMA, e.g. if EMA is decreasing or sloping downwards</li>
                                                                <li><i className="la la-check"></i> Also 8Week EMA and 30Week EMAs are also used in Weekly Timeframe with same scoring.</li>

                                                            </ul>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br></br>

                                            <div className="row">
                                                <div className="col-xl-11">
                                                    <div className="apps-content">
                                                        <h4 className="mt-2 mb-3 custom-color">
                                                            Points to keep in mind before using momentum dashboard.
                                                        </h4>

                                                        <ul>
                                                            <li><i className="la la-check"></i> Daily data is collected for stocks in Nifty 50 and Futures categories every evening by software automatically.</li>
                                                            <li><i className="la la-check"></i> MTF Score (MultTimeFrame Momentum score) is calculated on EOD basis.</li>
                                                            <li><i className="la la-check"></i> So for now, It's not live intraday data.</li>
                                                            <li><i className="la la-check"></i> Also data is not of high quality, so many times Broker APIs I'm using are not giving correct data or data on time, so check last date for data updation mentioned in table header.</li>
                                                            <li><i className="la la-check"></i> Since this is EOD momentum score, it is possible bullish/bearish momentum stocks can start showing reversals in 5m/15m/hourly timeframe so for taking trades, it's better to wait for same (bullish/bearish) momentum to align or some trading signal to come in lower timeframe like 15m or hourly. e.g. if you want to take long trade in a stock in Top 10 MTF bullish Score, may be wait for 8/20DEMA crossover in 15m or RSI to go above 60 in 15m or any other way of  confirmation you use for bullishness in 15m timeframe.</li>
                                                            <li><i className="la la-check"></i> With the same logic, it is also better to avoid reversal trades against the momentum score. e.g. if a stock is in top 10 MTF positive list, better to avoid bearish or reversal trades in lower TF as bigger TF momentum can start anytime.</li>
                                                            <li><i className="la la-check"></i> Personally, I completely avoid momentum trading when markets are on decline. Market texture widget on main dashboard (below image) gives this hint. It tells out of 50 stocks of Nifty 50, how many % are below 8DEMA, 20DEMA, 50DEMA and 200DEMA. So when majority of stocks are below 20 DEMA or 200DEMA, I have seen my way of momentum trading doesn't work. I'll be adding trendline chart for this % stocks above different DEMA to get a better insight.</li>
                                                        </ul>

                                                    </div>
                                                </div>
                                                <div className="row">
                                                <div className="col-xl-10">
                                                    <img
                                                        className="img-fluid content-img"
                                                        src={require("./../../images/blog/2.png")}
                                                        alt="..."
                                                        height="100"
                                                    />
                                                </div>
                                            </div>

                                            </div>
                                            <br></br>
                                            <div className="row">
                                                <div className="col-xl-11">
                                                    <div className="app-content">


                                                        <h4 className="mt-2 mb-3 custom-color">
                                                            How to use Dashboard ?
                                                    </h4>
                                                        <h6 className="content-text">
                                                            As shown in below image, on details page, <br></br>
                                                        </h6>
                                                        <div className="apps-content">
                                                            <ul>
                                                                <li><i className="la la-check"></i> First choose your watchlist.</li>
                                                                <li><i className="la la-check"></i> It will show all the stocks in watchlist sorted by MTF score for that particular day. Higher the score, more bullish momentum.</li>
                                                                <li><i className="la la-check"></i> Green is positive, Red is negative.</li>
                                                                <li><i className="la la-check"></i> You can filter further by ema crossover signals like LTP crossing above 8DEMA, 20DEMA or 8 DEMA crossing above 20 DEMA.</li>

                                                            </ul>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xl-10">
                                                    <img
                                                        className="img-fluid content-img"
                                                        src={require("./../../images/blog/1.png")}
                                                        alt="..."
                                                        height="100"
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xl-10">
                                                    <div class="empty"></div>
                                                </div>
                                            </div>
                                            
                                            

                                        </div>

                                    </PerfectScrollbar>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div></div>
                </div>
            </div>
            <Footer2 />
        </>
    );
}

export default Help;
