import React, { useEffect, useState, useCallback } from "react";
import { Nav, Tab, ProgressBar, Button } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-rangeslider/lib/index.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { useLocation, useParams } from "react-router-dom";
import Footer2 from "../layout/footer2";
import Header2 from "../layout/header2";
import Sidebar from "../layout/sidebar";
import Signals from "../layout/signals";
import BootstrapTable from 'react-bootstrap-table-next';
//import { format } from 'date-fns';
import { testService } from "../../api/_services/test.service";



function EMADashboard() {
    const [maxScore, setMaxScore] = useState(50)
    const [tableData, setTableData] = useState({
        columns: [],
        all: [],
        rows: [],
    });
    const [watchlist, setWatchlist] = useState(null)
    const [topIndices, setTopIndices] = useState([])
    const [indicesColumn, setIndicesColumn] = useState([]);
    const [selectedTopFilter, setSelectedTopFilter] = useState(10)
    const [selectedSignalFilter, setSelectedSignalFilter] = useState("all")
    const [currentTab, setCurrentTab] = useState(null);
    const params = useParams();
    const [lastDate, setLastDate] = useState("")
    // const [defaultWatchlist, setDefaultWatchlist] = useState(null)



    useEffect(() => {
        if (watchlist && watchlist.watchlists.length) {
            const defaultTab = watchlist.watchlists[0].value;
            const urlParams = new URLSearchParams(window.location.search);
            const selectedTabFromURL = urlParams.get('selectedTab');

            if (selectedTabFromURL) {
                const matchingTab = watchlist.watchlists.find(tab => tab.label === selectedTabFromURL);
                if (matchingTab) {
                    setCurrentTab(matchingTab.value);
                    fetchTableData(matchingTab.value);
                } else {
                    setCurrentTab(defaultTab);
                    fetchTableData(defaultTab);
                }
            } else {
                setCurrentTab(defaultTab);
                fetchTableData(defaultTab);
            }

            fetchTopIndices();
        }
    }, [watchlist]);


    const fetchAllLists = useCallback(async () => {
        console.log('lastSegment', params)
        const id = params.id;
        const data = await testService.getAllWatchlists(id);
        // console.log('data', data.data)
        // setColor(data.data.config.color)
        const formattedWatchlists = data.data.watchlists.map(watchlist => {
            return Object.entries(watchlist).map(item => {
                return {
                    label: item[0],
                    value: item[1],
                }
            })
        }).flat()
        // .sort((a, b) => {
        //     if (a.value < b.value) { return 1; }
        //     if (a.value > b.value) { return -1; }
        //     return 0;
        // });
        // console.log('formattedWatchlists', formattedWatchlists)
        setWatchlist({
            config: data.data.config,
            watchlists: formattedWatchlists
        })
    }, [params.id])

    useEffect(() => {
        console.log('useEffect paramsid')
        fetchAllLists();
    }, [params.id, fetchAllLists])


    const checkIfPositive = (num) => {
        return num > 0
    }

    const fetchTableData = async (name) => {
        console.log('name', name)
        const data = await testService.getData(name);

        // console.log('data', data)

        setLastDate(data.data.summary.date);
        // console.log(lastDate);

        const formattedData = Object.entries(data.data.all).map(item => {

            // console.log(item[1].ema_score.co_signals)
            const tempArr = [];
            const signals = Object.entries(item[1].ema_score.co_signals).map(item => {
                return item[1] !== 0 ? [...tempArr, { label: item[0], value: item[1] }] : []
            }).flat();



            // console.log('signals', signals)
            return {
                symbol: item[0],
                ltp: item[1].price.close,
                '1d': item[1].returns['1d'],
                '5d': item[1].returns['5d'],
                '1m': item[1].returns['30d'],
                '6m': item[1].returns['180d'],
                'mtf_score': item[1].scores['total_score'],
                // 'rsi_score': item[1].scores['total_rsi_score'],
                // 'ema_score': item[1].scores['total_ema_score'],
                // 'signals': item[1].ema_score.co_signals
                'd_ema_8': item[1].ema_score.price_above['d_ema_8'],
                'd_ema_20': item[1].ema_score.price_above['d_ema_20'],
                'd_ema_50': item[1].ema_score.price_above['d_ema_50'],
                'd_ema_200': item[1].ema_score.price_above['d_ema_200'],
                'w_ema_30': item[1].ema_score.price_above['w_ema_30'],
                'w_ema_8': item[1].ema_score.price_above['w_ema_8'],
                '8_above_20': item[1].ema_score.d['8_above_20'],
                '8w_above_30w': item[1].ema_score.w['8w_above_30w'],
                'close_co_8': item[1].ema_score.co_signals['close_co_8'],
                'close_co_20': item[1].ema_score.co_signals['close_co_20'],
                'co_8_20': item[1].ema_score.co_signals['8_20_co'],
                'golden_death_co': item[1].ema_score.co_signals['golden_death_co'],
                'close_co_8w': item[1].ema_score.co_signals['close_co_8w'],
                'close_co_30w': item[1].ema_score.co_signals['close_co_30w'],
                'co_8w_30w': item[1].ema_score.co_signals['8w_30w_co'],
                'signals': signals.filter(signal => signal.label !== 'score') // removing score from the signals list
            }
        }).sort((a, b) => b.mtf_score - a.mtf_score) // sorting data by mtf_score
        // console.log('formattedData', formattedData, watchlist)
        const getSymbolLink = (symbol) => `https://in.tradingview.com/chart/?symbol=${encodeURIComponent(symbol)}`;
        const columnData = [
            {
                dataField: 'symbol',
                text: 'Symbol',
                sort: true,
                // headerClasses: 'wid15'
                headerStyle: { width: '150px' },
                formatter: (cell, row) => (
                    <a href={getSymbolLink(cell)} target="_blank" rel="noopener noreferrer">
                        {cell}
                    </a>
                )
            },
            {
                dataField: 'ltp',
                text: 'LTP',
                sort: true,
                align: (cell, row, rowIndex, colIndex) => {
                    return 'right';
                },
                headerAlign: 'right',

            },

            {
                dataField: 'mtf_score',
                text: 'MTF Score',
                sort: true,
                headerStyle: { width: '180px' },

                formatter: (cell, row, id) => barFormatter(cell, row, id, 'hide_per')
            },
            // {
            //     dataField: 'rsi_score',
            //     text: 'RSI Score',
            //     sort: true,
            //     formatter: barFormatter
            // },
            // {
            //     dataField: 'ema_score',
            //     text: 'EMA Score',
            //     sort: true,
            //     formatter: barFormatter
            // },
            {
                dataField: 'signals',
                text: 'Signals',
                // sort: true,
                headerStyle: { width: '200px' },
                formatter: signalFormatter
            },
            {
                dataField: '8_above_20',
                text: '8/20D',

                formatter: scoreFormatter,
                align: (cell, row, rowIndex, colIndex) => {
                    return 'right';
                },
                headerAlign: 'right',

            },
            {
                dataField: 'd_ema_8',
                text: '8D',
                // sort: true,
                formatter: scoreFormatter,
                align: (cell, row, rowIndex, colIndex) => {
                    return 'right';
                },
                headerAlign: 'right',
                sortFunc: (a, b, order, dataField) => {
                    // console.log(a, b)
                    if (order === 'asc') {
                        return b - a;
                    }
                    return a - b; // desc
                }
            },
            {
                dataField: 'd_ema_20',
                text: '20D',
                // sort: true,
                formatter: scoreFormatter,
                align: (cell, row, rowIndex, colIndex) => {
                    return 'right';
                },
                headerAlign: 'right',
                sortFunc: (a, b, order, dataField) => {
                    if (order === 'asc') {
                        return b - a;
                    }
                    return a - b; // desc
                }
            },
            {
                dataField: 'd_ema_50',
                text: '50D',
                // sort: true,
                formatter: scoreFormatter,
                align: (cell, row, rowIndex, colIndex) => {
                    return 'right';
                },
                headerAlign: 'right',
                sortFunc: (a, b, order, dataField) => {
                    // console.log(a, b)
                    if (order === 'asc') {
                        return b - a;
                    }
                    return a - b; // desc
                }
            },
            {
                dataField: 'd_ema_200',
                text: '200D',
                // sort: true,
                formatter: scoreFormatter,
                align: (cell, row, rowIndex, colIndex) => {
                    return 'right';
                },
                headerAlign: 'right',
                sortFunc: (a, b, order, dataField) => {
                    // console.log(a, b)
                    if (order === 'asc') {
                        return b - a;
                    }
                    return a - b; // desc
                }
            },
            {
                dataField: '8w_above_30w',
                text: '8w/30w',

                formatter: scoreFormatter,
                align: (cell, row, rowIndex, colIndex) => {
                    return 'right';
                },
                headerAlign: 'right',

            },
            {
                dataField: 'w_ema_8',
                text: '8w',
                // sort: true,
                formatter: scoreFormatter,
                align: (cell, row, rowIndex, colIndex) => {
                    return 'right';
                },
                headerAlign: 'right',
                sortFunc: (a, b, order, dataField) => {
                    // console.log(a, b)
                    if (order === 'asc') {
                        return b - a;
                    }
                    return a - b; // desc
                }
            },
            {
                dataField: 'w_ema_30',
                text: '30w',
                // sort: true,
                formatter: scoreFormatter,
                align: (cell, row, rowIndex, colIndex) => {
                    return 'right';
                },
                headerAlign: 'right',
                sortFunc: (a, b, order, dataField) => {
                    // console.log(a, b)
                    if (order === 'asc') {
                        return b - a;
                    }
                    return a - b; // desc
                }
            },


        ]

        // console.log(formattedData)
        if (selectedTopFilter !== 'all') {
            const top5Data = filterTopStats(formattedData, selectedTopFilter)
            // console.log('top5Data', top5Data)
            setTableData((prevState) => ({
                ...prevState,
                columns: columnData,
                rows: top5Data,
                all: formattedData
            }));
        }
        else {
            setTableData((prevState) => ({
                ...prevState,
                columns: columnData,
                rows: formattedData,
                all: formattedData
            }));
        }

    }


    const onTabChange = (tab) => {
        // console.log('onTabChange', tab)
        // console.log('tab', tab)
        fetchTableData(tab)
    }

    const fetchTopIndices = async () => {
        const data = await testService.getData('INDICES');
        // console.log(data.data)


        const formattedData = Object.entries(data.data.summary.all_mtf_scores).map(item => {
            return {
                'name': item[0],
                'value': item[1]
            }
        });

        const columnData = [
            {
                dataField: 'name',
                text: 'Symbol',
                sort: true
            },
            {
                dataField: 'value',
                text: 'Score',
                sort: true,
                formatter: (cell, row, id) => barFormatter(cell, row, id, 'hide_per')
            }
        ]

        setIndicesColumn(columnData)

        setTopIndices(formattedData);
    }



    const scoreFormatter = (cell, row) => {
        const iconClassName = checkIfPositive(cell) ? 'fa fa-circle text-success' : 'fa fa-circle text-danger';
        return (
            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: '30px' }}>
                <i className={iconClassName} />
            </span>
        );
    }

    const barFormatter = (cell, row, id, percentage) => {
        return (
            <span className="wid15">
                <ProgressBar now={cell} label={percentage === 'hide_per' ? `${cell}` : `${cell}%`} variant={checkIfPositive(cell) ? 'success' : 'danger'} max={checkIfPositive(cell) ? maxScore : -maxScore} />
            </span>
        );
    }
    const signalFormatter = (cell, row) => {
        // console.log(cell)
        return (
            // <span style={{fontSize: '12px'}}> 
            //     {cell.length > 1 ? <span className="bg-success text-white" style={{padding: '5px 7px', borderRadius: '2px', fontWeight: 600}}>{watchlist ? watchlist.config.columns[cell[0]]?.[1].name : null}</span> : 
            //     cell.length === 1 ? <span className="bg-success text-white" style={{padding: '5px 7px', borderRadius: '2px', fontWeight: 600}}>{watchlist ? watchlist.config.columns[cell[0]]?.[1].name: cell[0]}</span> : ''}
            //     <span style={{fontSize: '12px'}}>{cell.length > 1 ? ` +${cell.length - 1}` : ''}</span>
            // </span>
            <span style={{ fontSize: '10px' }}>
                {
                    cell.map((item, index) => {
                        return (<span key={index}>
                            {item.value === 1 ?
                                (<span className="bg-success text-white" style={{ padding: '3px 5px', marginRight: '5px', borderRadius: '2px', fontWeight: 500 }}>{watchlist ? watchlist.config.columns[item.label]?.[1].name : null}</span>) :
                                <span className="bg-danger text-white" style={{ padding: '3px 5px', marginRight: '5px', borderRadius: '2px', fontWeight: 500 }}>{watchlist ? watchlist.config.columns[item.label]?.[-1].name : null}</span>}
                        </span>
                        )
                    })
                }
            </span>
        );
    }

    const onTableSignalFilter = (type) => {
        // console.log(tableData)

        const { all } = tableData;
        setSelectedTopFilter('all')
        switch (type) {
            case '8D':
                const top8Ddata = filterSignalStats(all, type)
                setTableData((prevState) => ({
                    ...prevState,
                    rows: top8Ddata,
                }));
                break;
            case '20D':
                const top20Ddata = filterSignalStats(all, type)
                setTableData((prevState) => ({
                    ...prevState,
                    rows: top20Ddata,
                }));
                setSelectedSignalFilter(20)
                break;
            case '820D':
                const top820Ddata = filterSignalStats(all, type)
                setTableData((prevState) => ({
                    ...prevState,
                    rows: top820Ddata,
                }));
                setSelectedSignalFilter(820)
                break;
            case 'golden':
                const goldenData = filterSignalStats(all, type)
                setTableData((prevState) => ({
                    ...prevState,
                    rows: goldenData,
                }));
                setSelectedSignalFilter(820)
                break;
            case 'death':
                const deathData = filterSignalStats(all, type)
                setTableData((prevState) => ({
                    ...prevState,
                    rows: deathData,
                }));
                setSelectedSignalFilter(820)
                break;
            case '8W':
                const top8wDdata = filterSignalStats(all, type)
                setTableData((prevState) => ({
                    ...prevState,
                    rows: top8wDdata,
                }));
                setSelectedSignalFilter(820)
                break;
            case '30W':
                const top30wdata = filterSignalStats(all, type)
                setTableData((prevState) => ({
                    ...prevState,
                    rows: top30wdata,
                }));
                setSelectedSignalFilter(820)
                break;
            case '830W':
                const top830wdata = filterSignalStats(all, type)
                setTableData((prevState) => ({
                    ...prevState,
                    rows: top830wdata,
                }));
                setSelectedSignalFilter(820)
                break;
            case 'all':
                setTableData((prevState) => ({
                    ...prevState,
                    rows: all,
                }));
                setSelectedSignalFilter('all')
                break;
        }
        setSelectedSignalFilter(type)
    }

    // const location = useLocation();
    // const searchParams = new URLSearchParams(location.search);
    // const selectedTab = searchParams.get("selectedTab");
    const onTableFilterChange = (type) => {
        // console.log(tableData)

        const { all } = tableData;
        setSelectedSignalFilter("all")
        switch (type) {
            case 'top_5':
                const top5Data = filterTopStats(all, 5)
                setTableData((prevState) => ({
                    ...prevState,
                    rows: top5Data,
                }));
                setSelectedTopFilter(5)
                break;
            case 'top_10':
                const top10Data = filterTopStats(all, 10)
                setTableData((prevState) => ({
                    ...prevState,
                    rows: top10Data,
                }));
                setSelectedTopFilter(10)
                break;
            case 'all':
                setTableData((prevState) => ({
                    ...prevState,
                    rows: all,
                }));
                setSelectedTopFilter('all')
                break;
        }
    }

    const filterTopStats = (data, num) => {
        // console.log('filterTopStats', data)
        const postive = data.filter(item => item.mtf_score > 0) || [];
        const negative = data.filter(item => item.mtf_score < 0) || [];
        // console.log('postive', postive, negative)
        return [...postive.slice(0, num), ...negative.slice(Math.max(negative.length - num, 0))]
    }

    const filterSignalStats = (data, num) => {
        console.log('filterSignalStats', data)
        console.log(num)
        var topData = []
        switch (num) {
            case '8D':
                topData = data.filter(item => (item.close_co_8 > 0) || (item.close_co_8 < 0)) || [];
                //return top8Ddata
                break;
            case '20D':
                topData = data.filter(item => (item.close_co_20 > 0) || (item.close_co_20 < 0)) || [];
                //return top20Ddata
                break;
            case '820D':
                topData = data.filter(item => (item.co_8_20 > 0) || (item.co_8_20 < 0)) || [];
                //return top820Ddata
                break;
            case 'golden':
                topData = data.filter(item => item.golden_death_co > 0) || [];
                //return top820Ddata
                break;
            case 'death':
                topData = data.filter(item => item.golden_death_co < 0) || [];
                //return top820Ddata
                break;
            case '8W':
                topData = data.filter(item => (item.close_co_8w > 0) || (item.close_co_8w < 0)) || [];
                //return top820Ddata
                break;
            case '30W':
                topData = data.filter(item => (item.close_co_30w > 0) || (item.close_co_30w < 0)) || [];
                //return top820Ddata
                break;
            case '830W':
                topData = data.filter(item => (item.co_8w_30w > 0) || (item.co_8w_30w < 0)) || [];
                //return top820Ddata
                break;

        }
        console.log('topData', topData)
        return topData
    }

    const [sidebar, setSidebar] = useState(true)

    useEffect(() => {
    }, [sidebar])


    return (
        <>
            <Header2 setSidebar={setSidebar} />
            <div className={`wrapper ${sidebar ? "sidebar-open" : ""}`}>




                <div className="content-body" id="signals">
                    <div className="container-fluid">
                        {

                            watchlist && watchlist.watchlists.length ?

                                <Nav variant="pills">
                                    {
                                        watchlist && watchlist.watchlists.map(tab => {
                                            return (
                                                <Nav.Link eventKey={tab.value} key={tab.value + "header"} onSelect={(k) => { setCurrentTab(k); onTabChange(k); }} active={currentTab === tab.value}>{tab.label}</Nav.Link>
                                            )
                                        })
                                    }
                                </Nav> : null
                        }
                        <div className="row">
                            <div className=" top-stocks">
                                {

                                    watchlist && watchlist.watchlists.length ? <Tab.Container defaultActiveKey={watchlist.watchlists[0].value}>
                                        <div className="card">
                                            <div className="card-header ">
                                                <div className="flex">
                                                    <Button size="sm" active={selectedSignalFilter == '8D'} onClick={() => onTableSignalFilter('8D')}>8D</Button>&nbsp;&nbsp;
                                                    <Button size="sm" active={selectedSignalFilter == '20D'} onClick={() => onTableSignalFilter('20D')}>20D</Button>&nbsp;&nbsp;
                                                    <Button size="sm" active={selectedSignalFilter == '820D'} onClick={() => onTableSignalFilter('820D')}>8/20D</Button>
                                                    <Button size="sm" active={selectedSignalFilter == 'all'} onClick={() => onTableSignalFilter('all')}>All</Button>
                                                    <Button size="sm" active={selectedSignalFilter == 'golden'} onClick={() => onTableSignalFilter('golden')}>Golden</Button>
                                                    <Button size="sm" active={selectedSignalFilter == 'death'} onClick={() => onTableSignalFilter('death')}>Death</Button>
                                                    <Button size="sm" active={selectedSignalFilter == '8W'} onClick={() => onTableSignalFilter('8W')}>8w</Button>
                                                    <Button size="sm" active={selectedSignalFilter == '30W'} onClick={() => onTableSignalFilter('30W')}>30w</Button>
                                                    <Button size="sm" active={selectedSignalFilter == '830W'} onClick={() => onTableSignalFilter('830W')}>8w/30w</Button>
                                                </div>


                                                <div className="flex">
                                                    <Button size="sm" active={selectedTopFilter == 5} onClick={() => onTableFilterChange('top_5')}>Top 5</Button>&nbsp;&nbsp;
                                                    <Button size="sm" active={selectedTopFilter == 10} onClick={() => onTableFilterChange('top_10')}>Top 10</Button>&nbsp;&nbsp;
                                                    <Button size="sm" active={selectedTopFilter == 'all'} onClick={() => onTableFilterChange('all')}>All</Button>
                                                </div>
                                                <div>
                                                    <span>Last updated : {lastDate.toString().slice(0, 10)}</span>

                                                </div>
                                            </div>
                                            <PerfectScrollbar>
                                                <div className="card-body open-position-table">
                                                    <div className="market-history market-order">
                                                        <Tab.Content>
                                                            {
                                                                watchlist && watchlist.watchlists.length ? watchlist.watchlists.map(tab => {
                                                                    return (
                                                                        <Tab.Pane eventKey={tab.value} key={tab.value}>
                                                                            <div className="table-responsive">
                                                                                {tableData.columns.length ?
                                                                                    <BootstrapTable noDataIndication="No Data Available" bootstrap4 defaultSorted={[{ dataField: 'mtf_score', order: 'desc' }]} keyField='symbol' data={tableData.rows} columns={tableData.columns} bordered={false} /> : null
                                                                                }
                                                                            </div>
                                                                        </Tab.Pane>
                                                                    )
                                                                }) : null
                                                            }
                                                        </Tab.Content>
                                                    </div>
                                                </div>
                                            </PerfectScrollbar>
                                        </div>
                                    </Tab.Container> : null
                                }
                            </div>
                        </div>


                    </div>


                </div>


            </div>
            <Footer2 />
        </>
    );
}

export default EMADashboard;
