import React, { useEffect, useState, useCallback } from "react";
import { Nav, Tab, ProgressBar, Button } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-rangeslider/lib/index.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { useParams } from "react-router-dom";
import Footer2 from "../layout/footer2";
import Header2 from "../layout/header2";
import Sidebar from "../layout/sidebar";
import BootstrapTable from 'react-bootstrap-table-next';
//import { format } from 'date-fns';
import { testService } from "../../api/_services/test.service";
import { Link } from 'react-router-dom';
import Chart from "chart.js/auto";
import { useRef } from "react";
import { Line } from 'react-chartjs-2';
import { format } from 'date-fns';
function Signals() {

    const [latest, setLatest] = useState({})
    const [exchg, setExchg] = useState("nse")
    const [dateLabels, setDateLabels] = useState([]);
    const [historicalData, setHistoricalData] = useState([]);
    const [aData, setAData] = useState([]);
    const [dData, setDData] = useState([]);
    const [ema20Data, setEma20Data] = useState([]);
    const [ema50Data, setEma50Data] = useState([]);
    const [ema200Data, setEma200Data] = useState([]);
    const [pmtfData, setPmtfData] = useState([]);
    const [nmtfData, setNmtfData] = useState([]);
    const [adRatioData, setAdRatioData] = useState([]);
    const [pnmtfData, setPnmtfData] = useState([]);
    const params = useParams();
    const chartRef1 = useRef(null); // Create a ref for the chart
    const chartRef2 = useRef(null);
    const chartRef3 = useRef(null);
    const chartRef4 = useRef(null);
    const chartRef5 = useRef(null);
    const chartRef6 = useRef(null);
    const fetchSummary = async () => {
        const data = await testService.getSummary("NIFTY50");
        Chart.defaults.elements.line.borderWidth = 2;

        console.log('summary data', data)


        const formattedData = data.data.latest[0];
        console.log('formattedData', formattedData)
        setLatest(formattedData);
        // Extracting d_ema_8 values from the history array
        const historicalDema8 = data.data.history.map(item => item.d_ema_8);
        const historicalDema20 = data.data.history.map(item => item.d_ema_20);
        const historicalDema50 = data.data.history.map(item => item.d_ema_50);
        const historicalDema200 = data.data.history.map(item => item.d_ema_200);
        const historicalDemaa = data.data.history.map(item => item.advances);
        const historicalDemad = data.data.history.map(item => item.declines);
        const historicalDemamtfp = data.data.history.map(item => item.positive_mtf);
        const historicalDemamtfn = data.data.history.map(item => item.negative_mtf);
        // Extracting dates from the history array and formatting them
        const historicalDates = data.data.history.map(item => {
            const date = new Date(item.date); // Assuming date is a string in ISO format
            return format(date, 'MM/dd/yyyy'); // Format the date as desired
        });

        // Setting date labels in the state
        setDateLabels(historicalDates);


        // console.log('historyData', historicalDema8);
        // Setting all historical d_ema_8 data in the state
        setHistoricalData(historicalDema8);
        setAData(historicalDemaa);
        setDData(historicalDemad);
        setEma20Data(historicalDema20);
        setEma50Data(historicalDema50);
        setEma200Data(historicalDema200);
        setPmtfData(historicalDemamtfp);
        setNmtfData(historicalDemamtfn);


        // Calculate the A/D Ratio and store it
        const adRatioArray = historicalDemaa.map((advances, index) => {
            const declines = historicalDemad[index];
            return advances / declines;
        });

        setAdRatioData(adRatioArray);

        const pnmtfArray = historicalDemamtfp.map((positive_mtf, index) => {
            const negative_mtf = historicalDemamtfn[index];
            return positive_mtf / negative_mtf;
        });

        setPnmtfData(pnmtfArray);
    }
    const last30DataPoints = historicalData.slice(-15);
    const last30DataPoints1 = aData.slice(-15);
    // const last30DataPoints2 = dData.slice(-15);
    const last30DataPoints3 = ema20Data.slice(-15);
    const last30DataPoints4 = ema50Data.slice(-15);
    const last30DataPoints5 = ema200Data.slice(-15);
    const last30DataPoints6 = pmtfData.slice(-15);
    // const last30DataPoints7 = nmtfData.slice(-15);
    // const last30DataPoints8 = adRatioData.slice(-15);
    // const last30DataPoints9 = pnmtfData.slice(-15);
    const last20Date = dateLabels.slice(-15);



    useEffect(() => {
        console.log('useEffect paramsid ' + params.id)
        const id = params.id;
        setExchg(id);
        if (id == "nse") {
            fetchSummary();
        }

    }, [params.id])


    const chartData = {
        labels: last20Date,
        datasets: [{
            label: '8 EMA',
            borderColor: latest.d_ema_8_pct > 50 ? '#1CBB82' : '#F96C8E',
            backgroundColor: latest.d_ema_8_pct > 50 ? '#29ffb275' : '#f96c8e6e',
            data: last30DataPoints,
            fill: true,
        }]
    };
    const chartData1 = {
        labels: last20Date,
        datasets: [{
            label: '20 EMA',
            borderColor: latest.d_ema_20_pct > 50 ? '#1CBB82' : '#F96C8E',
            backgroundColor: latest.d_ema_20_pct > 50 ? '#29ffb275' : '#f96c8e6e', // Add shadow
            data: last30DataPoints3,
            fill: true,
        }]
    };

    const chartData2 = {
        labels: last20Date,
        datasets: [{
            label: '50 EMA',
            borderColor: latest.d_ema_50_pct > 50 ? '#1CBB82' : '#F96C8E',
            backgroundColor: latest.d_ema_50_pct > 50 ? '#29ffb275' : '#f96c8e6e', // Add shadow
            data: last30DataPoints4,
            fill: true,
        }]
    };

    const chartData3 = {
        labels: last20Date,
        datasets: [{
            label: '200 EMA',
            borderColor: latest.d_ema_200_pct > 50 ? '#1CBB82' : '#F96C8E',
            backgroundColor: latest.d_ema_200_pct > 50 ? '#29ffb275' : '#f96c8e6e', // Add shadow
            data: last30DataPoints5,
            fill: true,
        }]
    };


    const chartData4 = {
        labels: last20Date,
        datasets: [{
            label: 'Advances',
            borderColor: latest.advances > latest.declines ? '#1CBB82' : '#F96C8E',
            backgroundColor: latest.advances > latest.declines ? '#29ffb275' : '#f96c8e6e', // Gold or #F96C8E background
            data: last30DataPoints1,
            fill: true,
        }]
    };

    const chartData5 = {
        labels: last20Date,
        datasets: [{
            label: '+ MTF',
            borderColor: latest.positive_mtf > latest.negative_mtf ? '#1CBB82' : '#F96C8E',
            backgroundColor: latest.positive_mtf > latest.negative_mtf ? '#29ffb275' : '#f96c8e6e', // Orange or #F96C8E background
            data: last30DataPoints6,
            fill: true,
        }]
    };

    const chartOptions = {
        plugins: {
            legend: {
                display: false,
            },
            },

        scales: {
            x: {
                display: false, // Hide the x-axis
            },
            y: {
                display: false, // Hide the y-axis
            }        },
        maintainAspectRatio: false, // Disable aspect ratio
    };


    return (
        <>
            <div className="price-grid section-padding" id="price">
                <div className="conainer-fluid">


                    {
                        exchg == "nse" ?
                            <div className="row">

                                <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6">

                                    <div className="card">

                                        <div className="card-body">
                                            <span className="d-flex align-items-center">
                                                <h6 className="p-2">A / D Ratio</h6>
                                                <h3 className="text-success p-2">{latest.advances}</h3>
                                                <h3 className="">/</h3>
                                                <h3 className="text-danger p-2">{latest.declines}</h3>

                                            </span>
                                            <div style={{ width: "100%", height: "110px" }}>
                                                <Line data={chartData4} options={chartOptions} ref={chartRef5} />
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6">
                                    <div className="card">

                                        <div className="card-body">
                                            <span className="d-flex align-items-center">
                                                <h6 className="p-2">  8 EMA</h6>
                                                {latest.d_ema_8_pct > 50 ?
                                                    <h3 className="text-success p-2">{latest.d_ema_8_pct}%</h3>
                                                    :
                                                    <h3 className="text-danger p-2">{latest.d_ema_8_pct}%</h3>
                                                }


                                            </span>

                                            <div style={{ width: "100%", height: "110px" }}>
                                                <Line data={chartData} options={chartOptions} ref={chartRef1} />
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6">
                                    <div className="card">

                                        <div className="card-body">
                                            <span className="d-flex align-items-center">
                                                <h6 className="p-2">  20 EMA</h6>
                                                {latest.d_ema_20_pct > 50 ?
                                                    <h3 className="text-success p-2">{latest.d_ema_20_pct}%</h3>
                                                    :
                                                    <h3 className="text-danger p-2">{latest.d_ema_20_pct}%</h3>
                                                }


                                            </span>
                                            <div style={{ width: "100%", height: "110px" }}>
                                                <Line data={chartData1} options={chartOptions} ref={chartRef2} />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6">
                                    <div className="card">

                                        <div className="card-body">
                                            <span className="d-flex align-items-center">
                                                <h6 className="p-2">  50 EMA</h6>
                                                {latest.d_ema_50_pct > 50 ?
                                                    <h3 className="text-success p-2">{latest.d_ema_50_pct}%</h3>
                                                    :
                                                    <h3 className="text-danger p-2">{latest.d_ema_50_pct}%</h3>
                                                }

                                            </span>
                                            <div style={{ width: "100%", height: "110px" }}>
                                                <Line data={chartData2} options={chartOptions} ref={chartRef3} />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6">
                                    <div className="card">

                                        <div className="card-body">
                                            <span className="d-flex align-items-center">
                                                <h6 className="p-2"> 200 EMA</h6>
                                                {latest.d_ema_200_pct > 50 ?
                                                    <h3 className="text-success p-2">{latest.d_ema_200_pct}%</h3>
                                                    :
                                                    <h3 className="text-danger p-2">{latest.d_ema_200_pct}%</h3>
                                                }
                                            </span>
                                            <div style={{ width: "100%", height: "110px" }}>
                                                <Line data={chartData3} options={chartOptions} ref={chartRef4} />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6">
                                    <div className="card">

                                        <div className="card-body">
                                            <span className="d-flex align-items-center">
                                                <h6 className="p-2">+/- MTF</h6>
                                                <h3 className="text-success p-2">{latest.positive_mtf}</h3>
                                                <h3 className="">/</h3>
                                                <h3 className="text-danger p-2">{latest.negative_mtf}</h3>

                                            </span>
                                            <div style={{ width: "100%", height: "110px" }}>
                                                <Line data={chartData5} options={chartOptions} ref={chartRef6} />
                                            </div>

                                        </div>
                                    </div>
                                </div>



                            </div>
                            : <div></div>}
                </div>
            </div>

        </>
    );
}

export default Signals;
